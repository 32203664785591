import * as React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import { Link } from "gatsby"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: center;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }

  a {
    color: white;
  }
`

const offer = () => {
  return (
    <>
      <Seo title="Nasza oferta" />
      <Layout>
        <SimpleBanner title="Nasza oferta">
          <StaticImage
            className="banner__image"
            src="../images/offer.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Oferta Zespołu Muzycznego Memento składa się z kilku wariantów:
          </p>
          <h2>Opcja „Podstawowa”</h2>
          <p>
            Zespół 3 osobowy, w którego skład wchodzą: instrumenty klawiszowe,
            gitara akustyczna, gitara elektryczna, 2 x wokal męski, wokal damski
            oraz pełna konferansjerka, tzw. wodzirejka, oprawa świetlna i
            nagłośnienie.
          </p>
          <h2>Opcja 4 osobowa</h2>
          <p>
            Opcja podstawowa rozbudowana o kolejnego członka zespołu - do wyboru
            saksofon lub skrzypce.
          </p>
          <h2>Opcja 5 osobowa</h2>
          <p>
            Zespół 5 osobowy, w którego skład wchodzą: instrumenty klawiszowe,
            gitara akustyczna, gitara elektryczna, skrzypce, saksofon, 2 x wokal
            męski, wokal damski oraz pełna konferansjerka, tzw. wodzirejka,
            oprawa świetlna i nagłośnienie.
          </p>

          <span>
            Posiadamy również Ofertę Specjalną: Zespół w pakiecie z Fotografem.
            &nbsp;<Link to="/contact">Zapytaj nas</Link> o szczegóły.
          </span>

          <p>
            Dodatkowo specjalnie dla WAS wraz z Pako Lorente Polska
            przygotowaliśmy niespodziankę! Każdy z Was, kto podpisze z nami
            umowę, otrzyma od nas prezent w postaci karty upominkowej o wartości
            500 zł do wykorzystania w butiku Pako Lorente Polska mieszczącym się
            w Centrum Handlowym Pogoria w Dąbrowie Górniczej.
          </p>

          <span>♡ Memento Group ♡</span>
        </Container>
      </Layout>
    </>
  )
}

export default offer
